import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useAuth } from './../AuthContext';
import { useLocation, useNavigate } from "react-router-dom";
import './Shift.css';

interface Shift {
    shift_id: number;
    deco_id: number;
    role_id: number;
    start_time_day: number;
    start_time_minutes: number;
    end_time_minutes: number;
    is_required_change: number;
    want_to_change: number;
    want_to_delete: number;
    deco_name: string;
}

interface Deco {
    deco_id: number;
    deco_name: string;
    deco_color: string;
}

interface Delete {
    shift_id: number,
    deco_name: String,
    role_name: String,
    start_time_day: number,
    start_time_minutes: number,
    end_time_minutes: number,
    username: String,
}

interface Required {
    your_shift_id: number,
    your_start_time_day: number,
    your_start_time_minutes: number,
    your_end_time_minutes: number,
    required_shift_id: number,
    user_id: number,
    user_name: String,
    deco_id: number,
    deco_name: String,
    role_id: number,
    role_name: String,
    required_start_time_day: number,
    required_start_time_minutes: number,
    required_end_time_minutes: number,
}

function uniqueAll<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}

interface ShiftTableProps {
    startHour: number;
    endHour: number;
}

const Shift: React.FC<ShiftTableProps> = ({ startHour, endHour }) => {
    const { token, setToken, refreshToken, setRefreshToken } = useAuth();
    const [nowday, setNowday] = useState<number>(1);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [deletes, setdeletes] = useState<Delete[]>([]);
    const [decos, setdecos] = useState<Deco[]>([]);
    const [required, setrequired] = useState<Required[]>([]);
    const [filteredShifts, setFilteredShifts] = useState<Shift[]>([]);
    const [canSwap, setCanSwap] = useState<boolean[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const user_id = location.state?.id;

    const getDecoColor = (deco_id: number): string => {
        const deco = decos.find(d => d.deco_id === deco_id);
        return deco ? deco.deco_color : 'transparent'; // 該当する色がなければ透明色にする
    };

    useEffect(() => {
        if (user_id && Number.isInteger(user_id)) {
            fetchShiftData();
            fetchRequiredData();
            fetchDeleteData(); // 追加
        } else {
            console.error('Error: user_id must be an integer');
        }
    }, [user_id]);

    useEffect(() => {
        filterShifts();
    }, [nowday, shifts]);

    const fetchShiftData = async () => {
        try {
            const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/shift/personal_shifts', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    id: String(user_id),
                }
            });

            const shiftData = response.data;

            if (Array.isArray(shiftData)) {
                const sortedShifts = shiftData.sort((a: Shift, b: Shift) => {
                    if (a.start_time_day === b.start_time_day) {
                        return a.start_time_minutes - b.start_time_minutes;
                    }
                    return a.start_time_day - b.start_time_day;
                });
                setShifts(sortedShifts);
            } else {
                console.error('取得したデータが無効です:', shiftData);
                setShifts([]);
            }
            console.log('シフトデータ:', shiftData);
        } catch (error) {
            console.error('APIリクエストに失敗しました:', error);
        }
        try {
            const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/shift/getdeco', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    id: String(user_id),
                }
            });

            // uniqueAllの戻り値をDeco[]として型アサーション
            const decoData = uniqueAll(response.data) as Deco[];

            if (Array.isArray(decoData)) {
                const sortedDecos = decoData.sort((a: Deco, b: Deco) => {
                    return a.deco_id - b.deco_id;
                });
                setdecos(sortedDecos);
            } else {
                console.error('取得したデータが無効です:', decoData);
                setdecos([]);
            }

            console.log('所属デコデータ:', decoData);

        } catch (error) {
            console.error('APIリクエストに失敗しました:', error);
        }
    };

    const fetchRequiredData = async () => {
        try {
            const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/shift/get_requested_data', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    id: String(user_id),
                },
            });
            const requiredData = response.data;
            if (Array.isArray(requiredData)) {
                const Vec_required: Required[] = requiredData;
                setrequired(Vec_required);

                // 各リクエストに対して時間帯をチェック
                const checks = await Promise.all(Vec_required.map(async (req) => {
                    const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/shift/check_shift', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        params: {
                            shift_id_a: req.required_shift_id,
                            shift_id_b: req.your_shift_id,
                            user_id_a: req.user_id,
                            user_id_b: user_id
                        },
                    });
                    return response.data.is_eligible; // 交換可能かどうかのフラグを返す
                }));

                setCanSwap(checks);
            } else {
                console.error('取得したデータが無効です:', requiredData);
                setdecos([]);
            }
            console.log('送られたリクエスト:', requiredData);
        } catch (error) {
            console.error('APIリクエストに失敗しました:', error);
        }
    };

    const fetchDeleteData = async () => {
        try {
            const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/shift/get_delete_shift', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    id: String(user_id),
                },
            });
            const deleteData = response.data;
            if (Array.isArray(deleteData)) {
                const Vec_delete: Delete[] = deleteData;
                setdeletes(Vec_delete);
            } else {
                console.error('取得したデータが無効です:', deleteData);
            }
            console.log('送られたリクエスト:', deleteData);
        } catch (error) {
            console.error('APIリクエストに失敗しました:', error);
        }
    };

    const filterShifts = () => {
        const filtered = shifts.filter(shift => shift.start_time_day === nowday);
        setFilteredShifts(filtered);
    };

    const handleLogout = async () => {
        if (!refreshToken) {
            console.error('リフレッシュトークンがありません');
            return;
        }

        try {
            await axios.post(
                'https://api.shiftmanager.bunkasai.info/api/user/logout',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                }
            );

            setToken(null);
            setRefreshToken(null);
            console.log('ログアウトしました');
            navigate('/');
        } catch (error) {
            console.error('ログアウトに失敗しました', error);
        }
    };

    const handleCellClick = (shift: Shift) => {
        navigate('/changeshift', {
            state: {
                shift_id: Number(shift.shift_id),
                id: user_id
            }
        });
    };

    const handleDecoClick = (deco: Deco) => {
        navigate('/decoshift', {
            state: {
                deco_id: Number(deco.deco_id),
                deco_name: String(deco.deco_name),
                user_id: Number(user_id)
            }
        });
    }

    const handleShiftSwap = async (fromShiftId: number, toShiftId: number) => {
        console.log(`シフトID ${fromShiftId} から ${toShiftId} への交換を承認しました`);
        try {
            await axios.get(
                'https://api.shiftmanager.bunkasai.info/api/shift/swap',
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                    params: {
                        id_a: String(fromShiftId),
                        id_b: String(toShiftId),
                    }
                }
            );

            // 承認したリクエストをリストから削除
            setrequired(prevRequired =>
                prevRequired.filter(req => req.required_shift_id !== toShiftId)
            );

            await fetchRequiredData();
            await fetchShiftData();

            console.log('シフトの交換リクエストが成功しました');

            navigate('/shift', { state: { id: user_id } });
        } catch (error) {
            console.error('シフトの交換に失敗しました');
        }
    };

    const handleShiftNotSwap = async (fromShiftId: number, toShiftId: number) => {
        console.log(`シフトID ${fromShiftId} から ${toShiftId} への交換を却下しました`);
        try {
            await axios.get(
                'https://api.shiftmanager.bunkasai.info/api/shift/notswap',
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                    params: {
                        id_a: String(fromShiftId),
                        id_b: String(toShiftId),
                    }
                }
            );

            // 却下したリクエストをリストから削除
            setrequired(prevRequired =>
                prevRequired.filter(req => req.required_shift_id !== toShiftId)
            );

            await fetchRequiredData();
            await fetchShiftData();

            console.log('シフトの交換却下リクエストが成功しました');
            navigate('/shift', { state: { id: user_id } });
        } catch (error) {
            console.error('シフトの交換却下に失敗しました');
        }
    };

    const handleShiftDelete = async (shift_id: number) => {
        console.log(`シフトID ${shift_id} の削除を承認しました`);
        try {
            await axios.get(
                'https://api.shiftmanager.bunkasai.info/api/shift/delete_shift',
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                    params: {
                        id: String(shift_id),
                    }
                }
            );

            // 承認したリクエストをリストから削除
            setdeletes(prevDeletes =>
                prevDeletes.filter(req => req.shift_id !== shift_id)
            );

            await fetchDeleteData();

            console.log('シフトの交換リクエストが成功しました');

            navigate('/shift', { state: { id: user_id } });
        } catch (error) {
            console.error('シフトの交換に失敗しました');
        }
    };

    const handleShiftNotDelete = async (shift_id: number) => {
        console.log(`シフトID ${shift_id} の交換を却下しました`);
        try {
            await axios.get(
                'https://api.shiftmanager.bunkasai.info/api/shift/not_delete_shift',
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                    params: {
                        id: String(shift_id)
                    }
                }
            );

            // 却下したリクエストをリストから削除
            setdeletes(prevDeletes =>
                prevDeletes.filter(req => req.shift_id !== shift_id)
            );

            await fetchDeleteData();

            console.log('シフトの削除却下リクエストが成功しました');
            navigate('/shift', { state: { id: user_id } });
        } catch (error) {
            console.error('シフトの削除却下に失敗しました');
        }
    };

    const hours = Array.from({ length: endHour - startHour + 1 }, (_, i) => startHour + i);
    const tenMinuteIntervals = [0, 10, 20, 30, 40, 50];

    const isShiftActive = (shift: Shift, hour: number, minute: number) => {
        const totalMinutes = hour * 60 + minute;
        return totalMinutes >= shift.start_time_minutes && totalMinutes < shift.end_time_minutes;
    };

    const isShiftStart = (shift: Shift, hour: number, minute: number) => {
        const totalMinutes = hour * 60 + minute;
        return totalMinutes === shift.start_time_minutes;
    };

    const isShiftEnd = (shift: Shift, hour: number, minute: number) => {
        const totalMinutes = hour * 60 + minute;
        return totalMinutes === shift.end_time_minutes - 10;
    };

    const formatMinute = (minute: number) => minute === 0 ? '00-' : `${minute}-`;
    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    };

    return (
        <div className="shift-container">
            <h1>あなたのシフト</h1>
            <button onClick={handleLogout} className="logout-button">ログアウト</button>
            <nav className="nav">
                <ul>
                    <li><button className="nav-day" onClick={() => setNowday(1)}>Day1</button></li>
                    <li><button className="nav-day" onClick={() => setNowday(2)}>Day2</button></li>
                    <li><button className="nav-day" onClick={() => setNowday(3)}>Day3</button></li>
                </ul>
            </nav>
            <div className="deco-list">
                {decos.map((deco) => (
                    <div key={deco.deco_id} className="deco-item">
                        <div className="deco-color" style={{ backgroundColor: deco.deco_color }}></div>
                        <span onClick={() => handleDecoClick(deco)}>{deco.deco_name}</span>
                    </div>
                ))}
            </div>
            <div className="table-container">
                <table className="shift-table">
                    <thead>
                        <tr>
                            <th className="time-header">時間\分</th>
                            {tenMinuteIntervals.map(minute => (
                                <th key={minute} className="minute-header">{formatMinute(minute)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {hours.map(hour => (
                            <tr key={hour}>
                                <th className="hour-header">{hour}</th>
                                {tenMinuteIntervals.map(minute => (
                                    <td key={`${hour}:${minute}`} className="shift-cell-container">
                                        {filteredShifts.map(shift => {
                                            const decoColor = getDecoColor(shift.deco_id);
                                            return isShiftActive(shift, hour, minute) && (
                                                <div
                                                    key={shift.shift_id}
                                                    className={`shift-cell ${isShiftStart(shift, hour, minute) ? 'start' : ''} ${isShiftEnd(shift, hour, minute) ? 'end' : ''}`}
                                                    style={{ backgroundColor: decoColor }}
                                                    onClick={() => {
                                                        if (shift.is_required_change === 0 && shift.want_to_change === 0) {
                                                            handleCellClick(shift);
                                                        } else {
                                                            alert("このシフトは変更できません。");
                                                        }
                                                    }}
                                                >
                                                    {isShiftStart(shift, hour, minute) ? shift.deco_name : '\u00A0'}
                                                </div>
                                            );
                                        })}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <main className="shift-requests">
                <h2>交換リクエストの承認</h2>
                <div className="table-responsive">
                    {required.length === 0 ? (
                        <p>現在交換のリクエストは来ていません</p>
                    ) : (
                        <>
                            <table className="requests-table">
                                <thead>
                                    <tr>
                                        <th>デコ</th>
                                        <th>役職</th>
                                        <th>変更前日時</th>
                                        <th>変更相手</th>
                                        <th>変更後日時</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {required.map((req, index) => (
                                        <tr key={req.required_shift_id}>
                                            <td>{req.deco_name}</td>
                                            <td>{req.role_name}</td>
                                            <td>
                                                {`Day ${req.your_start_time_day} ${formatTime(req.your_start_time_minutes)} - ${formatTime(req.your_end_time_minutes)}`}
                                            </td>
                                            <td>{req.user_name}</td>
                                            <td>
                                                {`Day ${req.required_start_time_day} ${formatTime(req.required_start_time_minutes)} - ${formatTime(req.required_end_time_minutes)}`}
                                            </td>
                                            <td>
                                                {canSwap[index] ? ( // 交換可能な場合のみ承認ボタンを表示
                                                    <button
                                                        onClick={() => handleShiftSwap(req.your_shift_id, req.required_shift_id)}
                                                        className="button approve"
                                                    >
                                                        承認
                                                    </button>
                                                ) : (
                                                    <span>交換不可</span>
                                                )}
                                                <button
                                                    onClick={() => handleShiftNotSwap(req.your_shift_id, req.required_shift_id)}
                                                    className="button reject"
                                                >
                                                    却下
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </main>

            <main className="shift-requests">
                <h2>削除リクエストの承認</h2>
                <div className="table-responsive">
                    {deletes.length === 0 ? (
                        <p>現在シフト削除のリクエストは来ていません</p>
                    ) : (
                        <>
                            <table className="requests-table">
                                <thead>
                                    <tr>
                                        <th>デコ</th>
                                        <th>役職</th>
                                        <th>日時</th>
                                        <th>名前</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deletes.map((req, index) => (
                                        <tr key={req.shift_id}>
                                            <td>{req.deco_name}</td>
                                            <td>{req.role_name}</td>
                                            <td>
                                                {`Day ${req.start_time_day} ${formatTime(req.start_time_minutes)} - ${formatTime(req.end_time_minutes)}`}
                                            </td>
                                            <td>{req.username}</td>
                                            <td>
                                                <button
                                                    onClick={() => handleShiftDelete(req.shift_id)}
                                                    className="button approve"
                                                >
                                                    承認
                                                </button>
                                                <button
                                                    onClick={() => handleShiftNotDelete(req.shift_id)}
                                                    className="button reject"
                                                >
                                                    却下
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </main>
        </div>
    );
};

export default Shift;