import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Callback: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleRedirectCallback = async () => {
            const hash = window.location.hash.substring(1); // ハッシュから先頭の '#' を削除
            const params = new URLSearchParams(hash);
            const idToken = params.get('id_token'); // IDトークンを取得

            if (!idToken) {
                console.error('ID Token が見つかりません');
                navigate('/login');
                return;
            }

            // IDトークンをサーバーに送信
            try {
                console.log('Sending request with ID Token:', idToken);

                const response = await axios.post('https://api.shiftmanager.bunkasai.info/api/token/google-login', {
                    id_token: idToken,
                });

                console.log('Response:', response);

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('refreshToken', response.data.refresh_token);

                navigate('/some-protected-route');
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error('トークン認証に失敗しました:', error.response?.data || error.message);
                } else {
                    console.error('トークン認証に失敗しました:', error);
                }
                navigate('/login');
            }
        };

        handleRedirectCallback();
    }, [navigate]);

    return (
        <div>
            <h1>認証中...</h1>
        </div>
    );
};

export default Callback;