import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useLocation, useNavigate } from "react-router-dom";
import './Shift.css';

interface Changer {
    end_time_minutes: number;
    shift_id: number;
    start_time_day: number;
    start_time_minutes: number;
    user_id: number;
    username: string;
}

const Changeshift: React.FC = () => {
    const [popupContent, setPopupContent] = useState("");
    const [popupContent2, setPopupContent2] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const { token, setToken, refreshToken, setRefreshToken } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [canchangeshifts, setcanchangeshifts] = useState<Changer[]>([]);
    const shift_id_from = location.state?.shift_id;
    const user_id = location.state?.id;
    const [selectedShiftId, setSelectedShiftId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const getCanSwapData = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/change/can_change_shift', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: { id: String(shift_id_from) }
            });
            console.log('API Response:', response.data);
            setcanchangeshifts(response.data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('エラーメッセージ:', error.response?.data);
                setError(`APIエラー: ${error.response?.data}`);
            } else {
                console.error('APIリクエストに失敗しました:', error);
                setError('APIリクエストに失敗しました');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCanSwapData();
    }, [token, shift_id_from]);

    const handleLogout = async () => {
        if (!refreshToken) {
            console.error('リフレッシュトークンがありません');
            return;
        }

        try {
            await axios.post(
                'https://api.shiftmanager.bunkasai.info/api/user/logout',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                }
            );

            setToken(null);
            setRefreshToken(null);
            console.log('ログアウトしました');
            navigate('/');
        } catch (error) {
            console.error('ログアウトに失敗しました', error);
        }
    };

    const handleShiftSwap = async () => {
        if (selectedShiftId === null) {
            console.error('選択されたシフトIDがありません');
            return;
        }

        try {
            await axios.get(
                'https://api.shiftmanager.bunkasai.info/api/change/send_request',
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                        id_a: String(shift_id_from),
                        id_b: String(selectedShiftId),
                    }
                }
            );
            console.log(`Shift ID ${selectedShiftId} との交換をリクエストしました`);
            setShowPopup(false);
            navigate('/shift', { state: { id: user_id } });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('エラーメッセージ:', error.response?.data);
                console.error('エラーステータス:', error.response?.status); // ステータスコードをログ出力
                console.error('エラー詳細:', error); // エラーの詳細をログ出力
            }
            console.error('APIリクエストに失敗しました:', error);
        }

    };

    const handleDelete = async () => {
        if (selectedShiftId === null) {
            console.error('選択されたシフトIDがありません');
            return;
        }

        try {
            await axios.get(
                'https://api.shiftmanager.bunkasai.info/api/change/delete_request',
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                        id: String(shift_id_from),
                    }
                }
            );
            console.log(`Shift ID ${selectedShiftId} との交換をリクエストしました`);
            setShowPopup2(false);
            navigate('/shift', { state: { id: user_id } });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('エラーメッセージ:', error.response?.data);
                console.error('エラーステータス:', error.response?.status); // ステータスコードをログ出力
                console.error('エラー詳細:', error); // エラーの詳細をログ出力
            }
            console.error('APIリクエストに失敗しました:', error);
        }

    };

    const handleBackToShift = () => {
        navigate('/shift', { state: { id: user_id } });
    };

    const handleCellClick = (shiftId: number) => {
        setSelectedShiftId(shiftId);
        const content = `この時間帯に交換をリクエストしますか？`;
        setPopupContent(content);
        setShowPopup(true);
    };

    const handleCellClick2 = (shiftId: number) => {
        setSelectedShiftId(shiftId);
        const content = `このシフトの削除をリクエストしますか？`;
        setPopupContent2(content);
        setShowPopup2(true);
    };

    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    };

    return (
        <div className="shift-container">
            <h1>シフト交換</h1>
            <div className="button-container">
                <button onClick={handleBackToShift} className="button approve">
                    シフト管理画面へ戻る
                </button>
                <button onClick={handleLogout} className="logout-button">ログアウト</button>
            </div>
            <div className="table-container">
                {canchangeshifts.length === 0 ? (
                    <p>交換可能なシフトはありません。</p>
                ) : (
                    <>
                        <table className="shift-table">
                            <thead>
                                <tr>
                                    <th className="time-header">日</th>
                                    <th className="time-header">開始時刻</th>
                                    <th className="time-header">終了時刻</th>
                                    <th className="time-header">名前</th>
                                    <th className="time-header">交換</th>
                                </tr>
                            </thead>
                            <tbody>
                                {canchangeshifts.map((shift) => (
                                    <tr key={shift.shift_id}>
                                        <td>{shift.start_time_day}</td>
                                        <td>{formatTime(shift.start_time_minutes)}</td>
                                        <td>{formatTime(shift.end_time_minutes)}</td>
                                        <td>{shift.username}</td>
                                        <td>
                                            <button onClick={() => handleCellClick(shift.shift_id)} className="button approve">
                                                交換
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>{popupContent}</p>
                        <div className="popup-buttons">
                            <button className="button approve" onClick={handleShiftSwap}>
                                はい
                            </button>
                            <button className="button reject" onClick={() => setShowPopup(false)}>
                                いいえ
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <button onClick={() => handleCellClick2(shift_id_from)} className="button reject">
                    削除
                </button>
            </div>
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>{popupContent2}</p>
                        <div className="popup-buttons">
                            <button className="button approve" onClick={handleDelete}>
                                はい
                            </button>
                            <button className="button reject" onClick={() => setShowPopup2(false)}>
                                いいえ
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Changeshift;