import React, { createContext, useContext, useState, ReactNode } from 'react';

// トークンとセット関数の型定義
interface AuthContextType {
    token: string | null;
    setToken: (token: string | null) => void;
    refreshToken: string | null;
    setRefreshToken: (refreshToken: string | null) => void;
}

// Contextの作成
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// プロバイダーコンポーネント
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);

    return (
        <AuthContext.Provider value={{ token, setToken, refreshToken, setRefreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

// Contextを使用するためのカスタムフック
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};