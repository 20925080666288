import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Shift from './pages/Shift'
import Decoshift from './pages/Decoshift';
import Login from './pages/Login';
import Callback from './pages/Callback';
import Changeshift from './pages/Changeshift';

const App: React.FC = () => {
    return (
        <div className='root'>
            <div className='App'>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/decoshift' element={<Decoshift />} />
                    <Route path='/shift' element={<Shift startHour={9} endHour={16} />} />
                    <Route path='/changeshift' element={<Changeshift />} />
                    <Route path='/Auth/callback' element={<Callback />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;