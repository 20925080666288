import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './../AuthContext';
import { useLocation, useNavigate } from "react-router-dom";
import './Shift.css';

interface Shift {
    shift_id: number;
    user_id: number;
    username: string;
    role_id: number;
    start_time_day: number;
    start_time_minutes: number;
    end_time_minutes: number;
    is_required_change: number;
    want_to_change: number;
}

interface Role {
    role_id: number;
    role_name: string;
}

interface TimeSlot {
    startTime: number;
    endTime: number;
}

const DAYS = ['Day1', 'Day2', 'Day3'];

const Decoshift: React.FC = () => {
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const [nowday, setNowday] = useState<number>(1);
    const { token, setToken, refreshToken, setRefreshToken } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const decoid = location.state?.deco_id;
    const deconame = location.state?.deco_name;
    const user_id = location.state?.user_id;

    const fetchShiftData = useCallback(async () => {
        try {
            const response = await axios.get('https://api.shiftmanager.bunkasai.info/api/deco/decoshift', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    id: String(decoid)
                }
            });

            console.log('デコシフトデータ:', response.data);

            if (response.data && Array.isArray(response.data.role)) {
                setRoles(response.data.role);
            } else {
                console.error('無効なロールデータ:', response.data.role);
                setRoles([]);
            }

            if (response.data && Array.isArray(response.data.shift)) {
                setShifts(response.data.shift);
            } else {
                console.error('無効なシフトデータ:', response.data.shift);
                setShifts([]);
            }

        } catch (error) {
            console.error('シフトデータの取得に失敗しました:', error);
        }
    }, [token, decoid]);

    useEffect(() => {
        console.log('fetchShiftDataを呼び出します'); // ここを追加
        fetchShiftData();
    }, []);

    useEffect(() => {
        if (shifts.length > 0) {
            const filteredShifts = shifts.filter(shift => shift.start_time_day === nowday + 1);
            const uniqueTimeSlots = Array.from(new Set(filteredShifts.map(shift => `${shift.start_time_minutes}-${shift.end_time_minutes}`)))
                .map(timeString => {
                    const [start, end] = timeString.split('-').map(Number);
                    return { startTime: start, endTime: end };
                })
                .sort((a, b) => a.startTime - b.startTime);
            setTimeSlots(uniqueTimeSlots);
        }
    }, [shifts, nowday]);

    const minutesToTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    };

    const handleBackToShift = () => {
        navigate('/shift', { state: { id: user_id } });
    };

    const handleLogout = async () => {
        if (!refreshToken) {
            console.error('リフレッシュトークンがありません');
            return;
        }

        try {
            await axios.post(
                'https://api.shiftmanager.bunkasai.info/api/user/logout',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                }
            );

            setToken(null);
            setRefreshToken(null);
            console.log('ログアウトしました');
            navigate('/');
        } catch (error) {
            console.error('ログアウトに失敗しました', error);
        }
    };

    return (
        <div className="decoshift-container">
            <h1>{deconame}シフト表</h1>
            <div className="button-container">
                <button onClick={handleBackToShift} className="button approve">
                    シフト管理画面へ戻る
                </button>
                <button onClick={handleLogout} className="logout-button">ログアウト</button>
            </div>
            <nav className="nav">
                <ul>
                    {DAYS.map((day, index) => (
                        <li key={day}>
                            <button
                                className={`nav-day ${nowday === index ? 'active' : ''}`}
                                onClick={() => setNowday(index)}
                            >
                                {day}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="decotable-container">
                <table className="decoshift-table">
                    <thead>
                        <tr>
                            <th className="decotime-header">時間帯</th>
                            {roles && roles.length > 0 && roles.map(role => (
                                <th key={role.role_id} className="role-header">
                                    {role.role_name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {timeSlots.map((timeSlot, index) => (
                            <tr key={index}>
                                <th className="decohour-header">
                                    {`${minutesToTime(timeSlot.startTime)} - ${minutesToTime(timeSlot.endTime)}`}
                                </th>
                                {roles && roles.length > 0 && roles.map(role => {
                                    const shiftUsers = shifts.filter(shift =>
                                        shift.start_time_day === nowday + 1 &&
                                        shift.start_time_minutes === timeSlot.startTime &&
                                        shift.end_time_minutes === timeSlot.endTime &&
                                        shift.role_id === role.role_id
                                    );
                                    return (
                                        <td
                                            key={role.role_id}
                                            className="decoshift-cell-container"
                                            style={{
                                                width: `${100 / roles.length}%`,
                                                minHeight: `${Math.max(40, shiftUsers.length * 20)}px`,
                                                height: 'auto',
                                                padding: '8px'
                                            }}
                                        >
                                            <div className="decoshift-cell">
                                                {shiftUsers.length > 0 ? (
                                                    <div className="user-list">
                                                        {shiftUsers.map((user, idx) => (
                                                            <div key={idx} className="user-item">
                                                                {user.username}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Decoshift;