import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useAuth } from './../AuthContext';
import './Shift.css';  // Import the Shift.css styles

declare global {
    interface Window {
        google: any;
    }
}

const Login: React.FC = () => {
    const { token, setToken, refreshToken, setRefreshToken } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            script.onload = initializeGoogleSignIn;
        };

        loadGoogleScript();
    }, []);

    const initializeGoogleSignIn = () => {
        window.google.accounts.id.initialize({
            client_id: '815679118187-eafe1nc9ng2oull2lfqfafsbfsco1ifr.apps.googleusercontent.com',
            callback: handleGoogleSignInCallback,
        });

        window.google.accounts.id.renderButton(
            document.getElementById('googleSignInButton'),
            { theme: 'outline', size: 'large' }
        );
    };

    const handleGoogleSignInCallback = async (response: any) => {
        console.log('Google Sign-In response:', response);
        const idToken = response.credential;
        handleServerAuthentication(idToken);
    };

    const handleServerAuthentication = async (idToken: string) => {
        try {
            console.log('Sending ID token to server (first 20 chars):', idToken.substring(0, 20));
            const response = await axios.post('https://api.shiftmanager.bunkasai.info/api/token/google-login', {
                id_token: idToken,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Server response:', response.data);
            setToken(response.data.token);
            setRefreshToken(response.data.refresh_token);
            navigate('/shift', { state: { id: response.data.id } });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('サーバー認証に失敗しました', {
                    message: error.message,
                    response: error.response?.data,
                    status: error.response?.status,
                });
            } else {
                console.error('サーバー認証に失敗しました', error);
            }
        }
    };

    useEffect(() => {
        const handleRedirectCallback = async () => {
            const hash = window.location.hash.substring(1);
            const params = new URLSearchParams(hash);
            const idToken = params.get('id_token');
            if (idToken) {
                try {
                    const response = await axios.post('https://api.shiftmanager.bunkasai.info/api/token/google-login', {
                        id_token: idToken,
                    });

                    setToken(response.data.token);
                    setRefreshToken(response.data.refresh_token);
                    navigate('/shift', { state: { id: response.data.id } });
                } catch (error) {
                    console.error('リダイレクトからのGoogleログインに失敗しました', error);
                }
            }
        };

        handleRedirectCallback();
    }, [navigate]);

    return (
        <div className="shift-container">
            <h1>シフト管理システム ログイン画面</h1>
            <div className="login-box">
                <div id="googleSignInButton"></div>
            </div>
            <a
                href="https://forms.gle/A8VuehCRFp1NGEFs8"
                className="button approve"
            >
                問い合わせフォーム
            </a>
        </div>
    );
};

export default Login;